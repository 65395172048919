import { useEffect, useState } from 'react';
import { IAssignmentEvent, init } from '@eppo/js-client-sdk';

import analytics from 'src/explore/services/analytics';

interface IEppoRandomizationProvider {
  waitForInitialization?: boolean;
  children: JSX.Element;
}

export const EppoRandomizationProvider = ({
  waitForInitialization = true,
  children,
}: IEppoRandomizationProvider ) => {
  const [ isInitialized, setIsInitialized ] = useState( false );

  useEffect(() => {
    if ( window.eppo && window.eppo.key ) {
      init({
        apiKey: window.eppo.key,
        assignmentLogger: {
          logAssignment( assignment: IAssignmentEvent ) {
            analytics.trackEvent(
              'Eppo Randomized Assignment',
              {
                ...assignment,
                'experiment-key': assignment.experiment,
                'experiment-value': assignment.variation,
              },
              false
            );
          },
        },
      })
        .then(() => setIsInitialized( true ))
        .catch(() => {
          // failed to initialize Eppo, still show everything
          setIsInitialized( true );
        });
    }
  }, []);

  if ( window.eppo && window.eppo.key && waitForInitialization && !isInitialized ) {
    return null;
  }
  return children;
};
