import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as accountActions from 'src/explore/data/account/actions';
import * as adminActions from 'src/explore/data/admin/actions';
import * as favoriteActions from 'src/explore/data/account_favorite_products/actions';
import * as purchaseRewardTiersActions from 'src/explore/data/purchase_reward_tiers/actions';
import * as rewardPromosActions from 'src/explore/data/reward_promos/actions';
import { useShopConfig } from 'src/explore/hooks/useShopConfig';
import analytics from 'src/explore/services/analytics';

export const App = () => {
  const dispatch = useDispatch();
  const shopConfig = useShopConfig();

  // Initial load
  useEffect(() => {
    dispatch( accountActions.fetch()).catch(() => {}); /* Ignore for now */
    dispatch( rewardPromosActions.fetch()).catch(() => {}); /* Ignore for now */

    // These are not available on Prize yet
    if ( !shopConfig.isWhitelabel ) {
      dispatch( adminActions.fetch()).catch(() => {}); /* Ignore for now */
      dispatch( favoriteActions.fetch()).catch(() => {}); /* Ignore for now */
      dispatch( purchaseRewardTiersActions.fetch()).catch(() => {}); /* Ignore for now */
    }
  }, [ dispatch, shopConfig ]);

  useEffect(() => {
    analytics.trackClicks( 'a, button, input[type=submit]' );
  }, []);

  return null;
};
